html {
  font-size: 18px;
  line-height: 1.2;
  -moz-text-size-adjust: 100%;
       text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", "Segoe UI", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  margin-bottom: 0;
}

img {
  display: block;
  max-width: 100%;
}

svg {
  -webkit-user-select: none;
  user-select: none;
}

/* custom form fields styling */

div[role="radiogroup"] {
  flex-direction: initial;
}

.form-fields {
  max-width: 800px;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}

.form-row > div {
  padding-bottom: 16px;
}

.form-section-title {
  margin-top: 24px;
  margin-bottom: 8px;
  color: black;
  font-weight: bold;
}

@media screen and (min-width: 62em) {
  .form-row > div {
    padding-bottom: 0;
  }
  .form-row {
    padding-bottom: 16px;
  }
  .form-section-title {
    margin-top: 16px;
    margin-bottom: 4px;
  }
  .form-fields > .form-section-title:first-child {
    margin-top: 8;
  }
}

/* Hack for centering logout and menu items */
*[role="menuitem"] > span {
  line-height: 0;
}

div > li[role="menuitem"] {
  color: white;
  font-size: 0.8rem;
}

/* RWD hacks for react-admin */

div[data-source="fts.search_query"],
div[data-source="meter_type"] {
  width: 50%;
}

@media screen and (max-width: 900px) {
  .list-page > div > div:last-child {
    overflow-x: auto;
  }
  .list-page > div > div:last-child > table {
    /* white-space: nowrap; */
  }
}

/* width helpers TODO: clean up unused */
.m-0 {
  margin: 0 !important;
}

.w-10 {
  width: 10%;
}
.w-20 {
  width: 20%;
}
.w-25 {
  width: 25%;
}
.w-30 {
  width: 30%;
}
.w-33 {
  width: 33%;
}
.w-34 {
  width: 34%;
}
.w-40 {
  width: 40%;
}
.w-45 {
  width: 45%;
}
.w-50 {
  width: 50%;
}
.w-60 {
  width: 60%;
}
.w-70 {
  width: 70%;
}
.w-75 {
  width: 75%;
}
.w-80 {
  width: 80%;
}
.w-90 {
  width: 90%;
}
.w-100 {
  width: 100%;
}
.w-auto {
  width: auto;
}
@media screen and (min-width: 48em) {
  .w-10-ns {
    width: 10%;
  }
  .w-20-ns {
    width: 20%;
  }
  .w-25-ns {
    width: 25%;
  }
  .w-30-ns {
    width: 30%;
  }
  .w-33-ns {
    width: 33%;
  }
  .w-34-ns {
    width: 34%;
  }
  .w-40-ns {
    width: 40%;
  }
  .w-45-ns {
    width: 45%;
  }
  .w-50-ns {
    width: 50%;
  }
  .w-60-ns {
    width: 60%;
  }
  .w-70-ns {
    width: 70%;
  }
  .w-75-ns {
    width: 75%;
  }
  .w-80-ns {
    width: 80%;
  }
  .w-90-ns {
    width: 90%;
  }
  .w-100-ns {
    width: 100%;
  }
  .w-auto-ns {
    width: auto;
  }
}
@media screen and (min-width: 62em) {
  .w-10-m {
    width: 10%;
  }
  .w-20-m {
    width: 20%;
  }
  .w-25-m {
    width: 25%;
  }
  .w-30-m {
    width: 30%;
  }
  .w-33-m {
    width: 33%;
  }
  .w-34-m {
    width: 34%;
  }
  .w-40-m {
    width: 40%;
  }
  .w-45-m {
    width: 45%;
  }
  .w-50-m {
    width: 50%;
  }
  .w-60-m {
    width: 60%;
  }
  .w-70-m {
    width: 70%;
  }
  .w-75-m {
    width: 75%;
  }
  .w-80-m {
    width: 80%;
  }
  .w-90-m {
    width: 90%;
  }
  .w-100-m {
    width: 100%;
  }
  .w-auto-m {
    width: auto;
  }
}
@media screen and (min-width: 76em) {
  .w-10-l {
    width: 10%;
  }
  .w-20-l {
    width: 20%;
  }
  .w-25-l {
    width: 25%;
  }
  .w-30-l {
    width: 30%;
  }
  .w-33-l {
    width: 33%;
  }
  .w-34-l {
    width: 34%;
  }
  .w-40-l {
    width: 40%;
  }
  .w-45-l {
    width: 45%;
  }
  .w-50-l {
    width: 50%;
  }
  .w-60-l {
    width: 60%;
  }
  .w-70-l {
    width: 70%;
  }
  .w-75-l {
    width: 75%;
  }
  .w-80-l {
    width: 80%;
  }
  .w-90-l {
    width: 90%;
  }
  .w-100-l {
    width: 100%;
  }
  .w-auto-l {
    width: auto;
  }
}

.meter-container {
  margin: 24px auto;
}
.meter-relative-container {
  position: relative;
  width: 100%;
  max-height: 180px;
  background: #f4f4f4;
  border-radius: 5px;
}

.meter-relative-container.dark {
  background: #d6d7d7;
}

.absolute-container {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}

.meter-device-id {
  height: 19.5%;
  font-size: calc(12px + (22 - 12) * ((100vw - 320px) / (1600 - 320)));
  font-weight: bold;
}

.light .meter-device-id {
  height: 30%;
}

.device-name {
  margin: 0 12px;
}

.last-value-time {
  margin: 0 12px;
  color: #666;
}

.meter-img {
  display: block;
  margin: 0 auto;
  height: auto;
  max-width: 480px;
}

.meter-no-value {
  font-family: "Roboto Mono", monospace;
  color: #333;
}

.meter-value {
  font-size: calc(18px + (42 - 24) * ((100vw - 320px) / (1920 - 320)));
  color: black;
  font-weight: bold;
  font-family: "Roboto Mono", monospace;
  min-height: 50%;
}

.meter-value-label {
  font-size: calc(11px + (26 - 14) * ((100vw - 320px) / (1920 - 320)));
  font-weight: normal;
  font-family: "Roboto Mono", monospace;
  margin-right: calc(5 * ((100vw - 320px) / (1920 - 320)));
}

.meter-time {
  height: 20%;
  font-size: calc(10px + (13 - 10) * ((100vw - 320px) / (1920 - 320)));
  text-align: center;
}

.flex-cent {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-baseline {
  display: flex;
  align-items: baseline;
  justify-content: center;
}

.values-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 90%;
}

.tc {
  text-align: center;
}

/* basic / incremental meter */

.basic-data {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 45%;
}

.basic-data .meter-value {
  font-size: calc(16px + (36 - 16) * ((100vw - 320px) / (1920 - 320)));
}

/* temperature meter */

.temperature-data {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 80%;
}

.temperature-tmin {
  color: #3db7da;
}
.temperature-tmax {
  color: #f44336;
}
.temperature-tave {
  color: #7dc6bc;
}

.temperature-tooltip-item li.recharts-tooltip-item:first-child * {
  color: #f44336;
}

.temperature-tooltip-item li.recharts-tooltip-item:nth-child(3) * {
  color: #3db7da;
}

/* temphumid meter */

.temphumid-data {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 80%;
}

.light .temphumid-data {
  height: 70%;
}

.temphumid-t {
  color: #7dc6bc;
}

.temphumid-h {
  color: #3db7da;
}

.temphumid-co2 {
  color: #643D85;
}

.temphumid-tooltip-item li.recharts-tooltip-item:nth-child(2) * {
  color: #f44336;
}

.chart-slider {
  height: 410px;
  overflow: hidden;
  position: relative;
  margin-bottom: 10px;
}

.chart-slider__content {
  position: relative;
  transition: top 0.3s ease;
}

.chart-slider__nav {
  background: #e8e8e8;
  border-radius: 5px;
  position: absolute;
  top: calc(50% - 27px);
  left: 0;
  width: 25px;
  height: 55px;
  z-index: 2;
}

.chart-slider__nav-btn {
  width: 100%;
  height: 50%;
  background: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s ease;
}

.chart-slider__nav-btn:disabled {
  opacity: 0.5;
  pointer-events: none;
}

.chart-slider__nav-btn::after {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 9px 12px 9px;
  border-color: transparent transparent #ffffff transparent;
  display: block;
}

.chart-slider__nav-btn--down::after {
  border-width: 12px 9px 0 9px;
  border-color: #ffffff transparent transparent transparent;
}

@media screen and (min-width: 1920px) {
  .meter-device-id {
    font-size: 22px;
  }
  .meter-value {
    font-size: 38px;
  }
  .meter-value-label {
    font-size: 20px;
    margin-right: 5px;
  }
  .meter-time {
    font-size: 13px;
  }
  .basic-data .meter-value {
    font-size: 36px;
  }
}

@keyframes GradientAnimation {
  0% {
    background-position: bottom left;
  }
  25% {
    background-position: center;
  }
  50% {
    background-position: top right;
  }
  75% {
    background-position: center;
  }
  100% {
    background-position: bottom left;
  }
}

/* temphumidco2 meter */
.temphumidco2-data {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 50%;
}

/* tilt meter */
.tilt-data {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 33%;
}

.tilt-data .values-column {
  flex-direction: row;
}

